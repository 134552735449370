h1 {
    color: black;
    font-weight: normal;
    font-size: 20px;
    position: relative;
}
main {
    width: 82%;
    margin-left: 18%;
    padding-bottom: 0;
}
.overview {
    display: flex;
    justify-content: space-between;
    margin-right: 2%;
}
.card, .cardManager {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0,0,0,0.25);
    width: 34%;
    height: 140px;
    display: flex;
    justify-content: space-evenly;
    background-color:#FFFFFF;
}
.card .cardTitleContent, .cardManager .cardTitleContent {
    width: 80%;
}
.cardManager {
    width: 45%;
}
.cardTitle {
    display: flex;
    align-items: center;
}
.cardTitle a {
    color: #F49A56;
    margin: 0 5px 2px 15px;
    text-decoration: none;
}
.donut {
    height: 120px;
    width: 120px;
    position: relative;
}
.baseDonut {
    position: absolute;
    left: 0;
    top: 0;
    color: rgba(83,67,67,0.2);
}
.donutGraph {
    color: #534343;
}
.donutValue {
    position: absolute;
    top: 47%;
    left: 47%;
    transform: translate(-50%,-50%);
    color: #F49A56;
    font-weight: bold;
    font-size: 1.6em;
}
.donutValue span {
    color: #534343;
}
.cardsAside {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.cardsAside .card {
    height: auto;
    width: calc(100% - 40px);
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    padding: 20px;
}
.cardsAside .card p {
    font-size: 15px;
    margin: 0;
    text-align: left;
    display: flex;
    align-items: baseline;
}
.cardsAside .card p svg {
    align-self: center;
}
.cardsAside .card p a {
    text-decoration: none;
    color: black;
}
.cardsAside .card p span {
    font-weight: bold;
    color: #F49A56;
    margin-left: 10px;
    margin-right: 5px;
}
.cardsAside .card:first-of-type p:first-of-type {
    margin-bottom: 12px;
}
.feedbackReceived svg {
    transform: scale(-1, 1);
}
section.card {
    height: auto;
    padding: 20px;
    width: calc(98% - 40px);
    margin-top: 35px;
    display: flex;
    flex-direction: column;
}
.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}
.cardHeader h2 {
    margin: 0;
}
.cardHeader .buttons {
    width: 25%;
    display: flex;
    justify-content: space-evenly;
}
.itemCard {
    padding: 15px;
    border-radius: 25px;
    box-shadow: 0 4px 10px rgba(0,0,0,0.25);
    width: calc(31% - 30px);
    padding-left: 25px;
    position: relative;
}
.itemCard div:first-child {
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% - 10px);
    height: 100%;
    border-radius: 25px;
    z-index: -1;
}
.itemCardHeader img {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin: 5px;
}
.itemCard h3 {
    margin: 0;
    display: flex;
    align-items: center;
    width: 70%;
}
.itemCard h3 svg {
    margin-right: 10px;
}
.itemCard div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.itemCard div p {
    width: 73%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.itemCard div div {
    margin-left: 2%;
    width: 25%;
    display: block;
    text-align: right;
}
.itemCard a {
    cursor: pointer;
    text-decoration: none;
    color: black;
}
.itemCard .link svg {
    font-size: 1.3em;
    color: black;
}
.cardContent {
    display: flex;
    gap: 1.4%;
}
.itemCard div:first-child::before {
    transform: scaleX(0);
    transform-origin: bottom right;
    content: " ";
    display: block;
    position: absolute;
    border-radius: 17px 25px 25px 17px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    inset: 0;
    z-index: -1;
    transition: transform .3s ease;
    background-color: rgba(241, 179, 57, 0.1)
}
.itemCard:hover div:first-child::before {
    transform: scaleX(1);
    transform-origin: bottom left;
}
@media screen and (max-width: 1400px) {
    main {
        width: 83%;
        margin-left: 17%;
    }
    .overview {
        margin-right: 1%;
    }
    .card, .cardManager {
        width: 31%;
        height: 140px;
    }
    .cardsAside {
        width: 25%;
    }
    section.card {
        width: calc(99% - 40px);
        margin-top: 25px;
    }
}
@media screen and (max-width: 1250px) {
    .card .cardTitleContent, .cardManager .cardTitleContent {
        width: calc(80% - 20px);
        padding-right: 20px;
    }
    .cardTitle {
        font-size: 1.3em;
    }
    .cardTitle a {
        margin: 0 5px 2px 5px;
    }
    .donutValue {
        left: 40%;
    }
}
@media screen and (max-width: 1050px) {
    .cardTitle {
        font-size: 1.1em;
    }
    .cardTitleContent p {
        font-size: .9em;
    }
    .cardsAside {
        width: 22%;
    }
    .cardsAside .card p {
        font-size: .8em;
    }
    section.card {
        width: calc(99% - 40px);
        margin-top: 25px;
    }
    .cardHeader .buttons {
        width: 35%;
    }
    .itemCard div p {
        width: 65%;
        font-size: .9em;
    }
    .itemCard div div {
        width: 28%;
        font-size: .9em;
    }
}