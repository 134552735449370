.Title {
    width: 40%;
}
.LineLink {
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration-line: none;
    color: black;
}
.LineLink:hover {
    cursor: pointer;
}
.LineLink:visited {
    color: black;
}
.Status {
    border-radius: 25px;
    box-shadow: 0 4px 10px rgba(0,0,0,0.2);
    padding: 25px 15px;
    width: 10%;
    text-align: center;
    position: relative;
}
.Status div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 25px;
}
.Date {
    width: 20%;
    text-align: center;
}
.Actions {
    width: 10%;
    display: flex;
    justify-content: space-evenly;
}

.Actions .link {
    background-color: #E0E3E3;
    color: black;
    border-radius: 50%;
    cursor: pointer;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Actions .link svg {
    font-size: 1.3em;
}
.Actions .link:hover {
    background-color: #F49A56;
    color: white;
}
li{
    list-style-type: none;
}
