.loginMain, formContainer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-size: cover;
    margin: 0;
}
.loginMain div, .loginMain form, .formContainer div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
}
.formContainer div {
    color: black;
}
.loginMain div img {
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.6;
}
.loginMain div {
    padding: 9% 20%;
    position: relative;
}
.loginMain form {
    z-index: 1;
}
.loginMain form h2{
    color: white;
    width: 55%;
    font-size: 40px;
    margin-left: auto;
    margin-right: auto;
}
.loginMain input, .formContainer input {
    border-radius: 4px;
    border: 1px solid #F49A56;
    color: #F49A56;
    padding: 10px;
    margin: 10px 0;
    width: 250px;
}
.loginMain button, .formContainer button {
    margin: 30px 10px 10px 10px;
}
.forgot {
    text-decoration: none;
    color: white;
    font-size: 12px;
    z-index: 1;
}
.loginMain h1 {
    color: white;
    text-align: center;
    position: absolute;
    width: 25%;
    right: 50px;
    bottom: 25px;
    white-space: pre-wrap;
    font-weight: lighter;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

}
.loginMain h1 img {
    width: 250px;
}
.loginError {
    background-color: rgba(255,0,0,.3);
    padding: 5px;
}
.loginError span {
    color: white !important;
}
.password {
    margin: 0;
    position: relative;
}
.passwordShowed{
    position: absolute;
    right: 5px;
}
.password .passwordHidden, .password .passwordShowed{
    position: absolute;
    right: 5px;
    transform: none;
    left: initial;
    top: initial;
    width: 20px;
    cursor: pointer;
}

.visited {
    color: white;
}