.mainManager {
    width: 82%;
    margin-left: 18%;
    padding-bottom: 0;
}
.mainManager h1 {
    color: black;
    font-weight: normal;
    font-size: 20px;
    position: relative;
}
.mainManager h1 img {
    height: 70px;
    width: 70px;
    position: absolute;
    right: -35px;
    top: -15px;
    z-index: -1;
}
.mainManager .firstLine, .mainManager .secondLine{
    display: flex;
    flex-direction: row;
}
.mainManager .firstLine {
    height: 37vh;
    margin: 15px 0 30px 0;
}
.mainManager .secondLine {
    min-height: calc(63vh - 130px);
    margin-bottom: 15px;
}
.mainManager .secondLine > div:first-of-type {
    margin-right: 20px;
    width: calc(70% - 60px);
}
.card {
    padding: 15px 20px;
    margin-right: 20px;
    border-radius: 10px;
    box-shadow: 0px 6px 14px rgba(177, 172, 168, 0.25);
    width: calc(27% - 60px);
    height: calc(100% - 30px);
    background-color: #FFFFFF;
}
.card:first-of-type{
    width: calc(43% - 60px);
}
.card h3 {
    font-weight: normal;
    font-size: 16px;
    margin: 0 0 10px 0;
}
.card div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: inherit;
}
.card div img {
    height: 60%;
}
.card div p {
    font-size: 14px;
    text-align: center;
}
.cardsAside {
    width: 27%;
}
.cardsAside .card {
    width: calc(100% - 40px);
    margin-right: 0;
    height: calc(40% - 30px);
}
.cardsAside .card:first-of-type {
    margin-bottom: 20px;
    padding-bottom: 0;
    height: calc(60% - 35px);
}
.cardsAside div {
    height: calc(100% - 52px);
    margin: auto;
}
.cardsAside div img {
    height: 75px;
}
.secondLine .card {
    width: calc(70% - 60px);
}
.secondLine .card div img {
    height: 200px;
}
.secondLine .cardsAside .card {
    width: calc(100% - 40px);
    height: calc(100% - 30px);
}
.card .donut {
    height: calc(100% - 52px);
    position: relative;
    display: block;
}
.baseDonut {
    position: absolute;
    top: 15%;
    color: rgba(83,67,67,0.2);
}
.donutGraph {
    position: absolute;
    top: 15%;
    color: #534343;
}
.donutValue {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #F49A56;
    font-weight: bold;
    font-size: 1.4em;
}
.donutValue span {
    color: #534343;
}
.card .engagement {
    flex-direction: row;
}
.card .engagement .separator {
    border: 1px solid #DAE1E7;
    height: calc(100% - 52px);
    margin: 0 15px;
}
.card .engagement div {
    width: calc(33% - 20px);
    justify-content: flex-start;
}
.card .engagement h5 {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
    margin-top: 0;
}
.card .engagement h5 span {
    width: 6px;
    height: 33px;
    border-radius: 4px;
    display: block;
}
.avatarLink {
    width: 25%;
}
.avatarLink figure {
    border-radius: 50%;
    width: 100%;
    background-color: lightgrey;
    border: 3px solid white;
    overflow: hidden;
    margin: 0;
    position: relative;
}
.avatarLink figure img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 150%;
    max-height: 150%;
    min-width: 100%;
    min-height: 100%;
}
.card .engagement div figure div {
    width: 100%;
    height: 100%;
    justify-content: center;
}
.card .engagement div a {
    transform: translateX(-10%);
    text-decoration: none;
}
.card .engagement .LessCommitted {
    background-color: #F12B2C;
}
.card .engagement .Committed {
    background-color: #FEC400;
}
.card .engagement .VeryCommitted {
    background-color: #29CC97;
}
.card .engagement .engagementCollaborators {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
}

@media screen and (max-width: 1400px) {
    .card .donut {
        width: 90px;
        height: calc(100% - 40px);
    }
    .donutValue {
        left: 60%;
    }
}
@media screen and (max-width: 1250px) {
    .mainManager {
        width: 84%;
        margin-left: 16%;
    }
    .card:first-of-type {
        width: calc(45% - 60px);
    }
    .cardsAside .card {
        width: calc(100% - 40px);
    }
    .mainManager .secondLine > div:first-of-type {
        width: calc(72% - 60px);
    }
    .card h3 {
        font-size: 1em;
        margin-top: 0;
    }
    .card .engagement h5 {
        margin-bottom: 10px;
    }
    .card .engagement div {
        width: calc(33% - 10px);
    }
    .card .engagement .separator {
        margin: 0 8px;
    }
    .card .engagement div img {
        width: 30px;
        height: 30px;
    }
    .card div p {
        font-size: 12px;
    }
}
@media screen and (max-height: 850px) and (min-width: 1850px) {
    .mainManager .firstLine {
        height: 43vh;
    }
}