.Header {
    padding: 15px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
}
.Header div {
    display: flex;
    align-items: center;
}
.headerTalent {
    margin-left: 0.5%
}
.headerTitle {
    margin-left: 2%;
    width: 40%;
}
.headerDate {
    margin-left: 12%;
    width: 20%;
    justify-content:center;
}
.headerStatus {
    margin-left: 12%
}
.List {
    width: calc(100% - 40px);
    padding: 15px 20px;
    background-color: #FFFFFF;
    height: inherit;
    border-radius: 25px;
}
.List h3 {
    font-weight: normal;
    font-size: 16px;
    margin: 10px 0;
}
.List .EmptyStage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.List .EmptyStage img {
    height: 200px;
}
.entry {
    list-style: none;
    border-top: 1px solid rgba(177, 172, 168, 0.4);
    width: 100%;
    padding: 5px 0px;
}
.entry a {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    text-decoration: none;
    color: black;
    width: 100%;
}
.entry a div {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: start;
}
.entry .Status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}
.List .entry {
    display: flex;
    flex-direction: row;
}
.List .EntryHeader div {
    display: flex;
    flex-direction: row;
    justify-content: left;
    color: #B1ACA8;
    font-size: 14px;
    width: 25%;
}
.List .EntryHeader div img {
    height: 20px;
    margin-left: 10px;
}
.List .Sharing {
    height: auto;
}
.List .EntryPagination {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    height: 40px;
    color: grey;
    font-size: 14px;
}
.List .EntryPagination .active {
    color: black;
    cursor: pointer;
}
.pageTitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.addButton {
    margin-left: calc(100% - 425px);
    margin-right: 25px;
    border: 1px solid #F49A56;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    border-radius: 25px;
    padding: 15px 25px;
    background-color: white;
    color: #F49A56;
    font-weight: bold;
    text-decoration: none;
    background-color: #F49A56;
    color: white;
    padding-left: 50px;
}
.addButtonIcon {
    position: absolute;
    transform: translateX(-150%) translateY(15%);
    z-index: 3;
}
.addButtonIconHover {
    position: absolute;
    transform: translateX(-150%) translateY(15%);
    z-index: 2;
}
.addButton:hover {
    background: white;
    color: #F49A56;
    transition: background-color .2s;
}
.addButton:hover .addButtonIcon{
    animation-name: addButtonIcon;
    animation-duration:.2s;
    opacity: 0%;
    
}
.goalIcon {
    height:40px;
    width:40px;
}


@keyframes addButtonIcon {
    0%{
        opacity: 100%;
    }
    50%{
        opacity: 50%;
    }
    100%{
        opacity: 0%;
    }
}