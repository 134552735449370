.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    height: 70px;
    margin-bottom: 20px;
}
.mainManager {
    width: 82%;
    margin-left: 18%;
    padding-bottom: 0;
}
.mainManager h1 {
    color: black;
    font-weight: normal;
    font-size: 20px;
    position: relative;
    width: max-content;
    margin-bottom: 0;
}
.mainManager h1 img {
    height: 70px;
    width: 70px;
    position: absolute;
    right: -35px;
    top: -15px;
    z-index: -1;
}
.name div {
    display: flex;
}
.name div p {
    margin: 8px 25px 0 0;
}
.Container {
    display: flex;
}
.Container .LeftSide {
    margin-top: 55px;
    width: 74%;
    margin-right: 3%;
}
.Container .RightSide {
    width: 21%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}
.avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
}
.avatar img, .avatar div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 150%;
    max-height: 150%;
    min-width: 100%;
    min-height: 100%;
    font-size: 54px;
}

.Container .LeftSide .card {
    width: calc(100% - 40px);
}
.emptyStage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.emptyStage img {
    height: 120px;
}
.card {
    width: calc(100% - 40px);
    padding: 15px 20px;
    border-radius: 10px;
    box-shadow: 0px 6px 14px rgba(177, 172, 168, 0.25);
    background-color: #FFFFFF;
}
.card h3 {
    font-weight: normal;
    font-size: 16px;
    margin: 10px 0;
}
.RightSide .card{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 20px;
}
.RightSide .card .content {
    width: 50%;
    margin: 15px auto;
    font-size: 14px;
}
.RightSide .card p span, .RightSide .card div span {
    font-weight: bold;
    font-size: 22px;
    margin-right: 5px;
}
.card .separator {
    border: 0.5px solid #D8D5D4;
    width: 80%;
}
.card .graphContainer {
    display: flex;
    flex-direction: column;
    max-width: 100%;
}
.progress {
    display: flex;
    align-items: end;
    margin: 15px 0;
}
.progress span {
    margin-right: 15px;
}
.progress progress {
    width: 210px;
    margin-bottom: 5px;
}
.progress progress[value]{
    -webkit-appearance: none;
    appearance: none;
    height: 0.6em;
    margin-left: 15px;
}
.progress progress[value]::-webkit-progress-bar {
    background-color: #D8D5D4;
    border-radius: 25px;
    box-shadow: none;
}
.progress progress[value]::-webkit-progress-value {
    border-radius: 25px;
    background-color: #5E5955;
}
.LeftSide .card{
    margin-bottom: 25px;
}
@media screen and (max-width: 1250px) {
    .mainManager {
        width: 84%;
        margin-left: 16%;
    }
}