.modalForm {
    margin: 35px auto;
    border-radius: 25px;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 0 4px 10px rgba(0,0,0,0.2);
    padding: 25px;
}
.form input {
    border-radius: 25px;
    border: 1px solid lightgray;
    padding: 10px;
}
.form div {
    box-shadow: none;
    flex-grow: 0;
    -webkit-flex-grow: 0;
}
.form div:before {
    height: 0;
}
.form fieldset {
    border: none;
}
.form .Input {
    border-radius: 25px;
    border: 1px solid lightgray;
    padding: 10px;
    background-color: white;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 25px;
}
.buttonContainer button{
    display: flex;
}
.form .tooltipHandler input {
    border-radius: 0;
    border: none;
    padding: 0;
}
.titleInput {
    display: flex;
    flex-direction: column;
    position: relative;
}

.buttonContainer button p{
    margin: 0 0 0 30px;
}
.hrcomment {
    display: flex;
    margin-bottom: 25px;
}
.addHRComment {
    display: flex;
    align-items: center;
}
.addHRComment form {
    width: 95%;
}
.addHRComment form input {
    width: 95%;
}