.headerButton {
    display: flex;
    justify-content: flex-end;
    margin: 25px 2% 25px 0;
}
.section {
    border: 1px solid #F4DDDC;
    border-radius: 25px;
    box-shadow: 0 4px 10px rgba(0,0,0,0.2);
    padding: 25px;
    position: relative;
    margin-right: 2%;
}
.section h1 {
    margin: 0;
    background: #F3F5F7;
    padding: 10px;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
}
.section .filterSection {
    margin: 15px 0;
}
.Table header {
    padding: 15px 25px;
    border-radius: 25px;
    background-color: #EFF1F1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}
.Table header .Title {
    width: 40%;
    text-align: left;
}
.Table header .Status {
    width: 10%;
}
.Table header .Date {
    width: 20%;
}
.Table header .Actions {
    width: 10%;
}
.Table ul {
    padding: 0;
}
.Table li {
    padding: 10px 25px;
    list-style: none;
}
.Table li a {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
}

@media screen and (max-width: 1400px) {
    .section {
        margin-right: 1%;
    }
}