.mainManager {
    width: 82%;
    margin-left: 18%;
    padding-bottom: 0;
}
.mainManager h1 {
    color: black;
    font-weight: normal;
    font-size: 20px;
    position: relative;
}
.mainManager h1 img {
    height: 70px;
    width: 70px;
    position: absolute;
    right: -35px;
    top: -15px;
    z-index: -1;
}

.mainManager .firstLine {
    display: flex;
    flex-direction: row;
    height: 37vh;
    margin: 15px 0 30px 0;
}
.mainManager .secondLine {
    min-height: calc(63vh - 130px);
    margin-bottom: 15px;
}

.card {
    padding: 15px 20px;
    margin-right: 20px;
    border-radius: 10px;
    box-shadow: 0px 6px 14px rgba(177, 172, 168, 0.25);
    width: calc(50% - 60px);
    height: calc(100% - 30px);
    background-color: #FFFFFF;
}
.card h3 {
    font-weight: normal;
    font-size: 16px;
    margin: 0 0 10px 0;
}
.secondLine .card {
    width: calc(70% - 60px);
}
.secondLine .card div img {
    height: 200px;
}
.secondLine .cardsAside .card {
    width: calc(100% - 40px);
    height: calc(100% - 30px);
}
.subcardContainer {
    display: flex;
    justify-content: space-between;
    height: 65%;
}

.subcard {
    width: calc(49% - 30px);
    height: calc(100% - 30px);
    background: rgba(244, 154, 86, 0.4);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 15px;
    color: #5E5955;
    flex-wrap: wrap;
}
.subcard p {
    margin:0
}

.subcard p span {
    font-weight: bold;
    margin-right: 10px;
    font-size: 22px
}
.card .graphContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    max-width: 100%;
}
.card .graphContainer p {
    font-weight: bold;
    color: #5E5955;
}
.progress {
    display: flex;
    align-items: end;
    margin: 15px 0;
}
.progress span {
    margin-right: 5px;
}
.progress progress {
    width: 210px;
    margin-bottom: 5px;
}
.progress progress[value]{
    -webkit-appearance: none;
    appearance: none;
    height: 0.6em;
    margin-left: 15px;
}
.progress progress[value]::-webkit-progress-bar {
    background-color: #D8D5D4;
    border-radius: 25px;
    box-shadow: none;
}
.progress progress[value]::-webkit-progress-value {
    border-radius: 25px;
    background-color: #5E5955;
}

.table {
    display: flex;
}
.titleColumn {
    width: 350px;
}
.titleColumn div, .tableColumn div{
    font-weight: bold;
    font-size: 14px;
    color: #5E5955;
    height: 25px;
    padding: 5px;
}
.tableContent {
    display: flex;
    height: 100%;
    width: 100%;
    overflow-x:scroll;
}
.tableContent .tableColumn {
    width: 80px;
    text-align:center;
}
.avatar {
    height: 50px !important;
}
.tableContent .tableColumn .avatar figure {
    height: 45px !important;
    width: 45px;
    margin: auto;
}

.tableContent .tableColumn .avatar figure div {
    height: 45px !important;
    width: 45px;
    margin: auto;
    padding: 0;
}


@media screen and (max-width: 1250px) {
    .mainManager {
        width: 84%;
        margin-left: 16%;
    }
    .card:first-of-type {
        width: calc(45% - 60px);
    }
    .cardsAside .card {
        width: calc(100% - 40px);
    }
    .mainManager .secondLine > div:first-of-type {
        width: calc(72% - 60px);
    }
    .card h3 {
        font-size: 1em;
        margin-top: 0;
    }
    .card div p {
        font-size: 12px;
    }
}
@media screen and (max-height: 850px) and (min-width: 1850px) {
    .mainManager .firstLine {
        height: 43vh;
    }
}