.modalForm {
    width: 95%;
    margin: 35px auto;
    border-radius: 25px;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 0 4px 10px rgba(0,0,0,0.2);
    padding: 25px;
}
.modalForm header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}
.modalForm header .entryTitle {
    display: flex;
    position: relative;
}
.modalForm header .entryTitle .userGuide {
    position: absolute;
    bottom: -20px;
    color: black;
    text-decoration: underline;
    display: flex;
    align-items: center;
}
.modalForm header .entryTitle .userGuide img {
    margin-right: 10px;
    width: 17px;
}

.modalForm header h1 {
    margin-left: 30px;
    font-size: 1.3em;
}
.form {
    display: flex;
    flex-direction: column;
}
.form input, .form textarea {
    border-radius: 25px;
    border: 1px solid lightgray;
    padding: 10px;
}
.form textarea {
    height: 120px;
}
.form label {
    margin: 20px 0;
}
.form div {
    box-shadow: none;
    flex-grow: 0;
    -webkit-flex-grow: 0;
}
.form div:before {
    height: 0;
}
.form fieldset {
    border: none;
}
.form svg {
    color: #EFAC39;
    border-radius: 50%;
    border: 1px solid #EFAC39;
    margin: 0 15px;
}
.form button svg {
    color: #EFAC39;
    border: 0;
    margin: 0;
}
.form .titleAccordion {
    font-weight: normal;
    font-style: italic;
    margin: 0;
}
.form .Input {
    border-radius: 25px;
    border: 1px solid lightgray;
    padding: 10px;
    background-color: white;
}
.form .Input div {
    padding: 0;
    width: 100%;
    justify-content: space-between;
    font-family: "SofiaPro";
}
.DateInput input {
    text-align: center;
    color:#F49A56;
    border: 2px solid #F49A56;
    background-color: white;
}
.buttonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 25px;
    margin-top: 25px;
}
.buttonContainer button{
    display: flex;
}
.form .tooltipHandler input {
    border-radius: 0;
    border: none;
    padding: 0;
}
.titleInput {
    display: flex;
    flex-direction: column;
    position: relative;
}
.Status{
    height: 33px;
    padding: 10px;
    display: flex;
    align-items: center;
}
.Status div{
    height: inherit;
    margin-right: 4px;
}
.buttonContainer button p{
    margin: 0 0 0 30px;
}
.inputManager {
    background-color: rgba(55,55,55, 0.1);
    border-radius: 35px;
    padding: 5px 25px;
    margin-top: 15px;
}
.inputManager p {
    margin: 5px 0;
}
.inputManager textarea {
    width: calc(100% - 20px);
    height: auto;
}
.form h3 {
    text-align: center;
}
.goalLine {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 20px;
}
.goalLine div{
    display: flex;
    flex-direction: column;
}
.goalLine div label {
    margin-bottom: 10px;
}
.goalLine_firstItem {
    width: 100%;
}
.goalLine_firstItem input {
    width: 95%;
}
.goalLine_secondItem {
    width: 350px;
}
.goalLine_secondItem svg {
    margin-right: 0;
}
.goalLine_secondItem .DateInput div {
    flex-direction: row;
}
.answerSeparator {
    display: flex;
    gap: 15px;
}
.answerSeparator div {
    width: 50%;
}
.answerSeparator div input, .answerSeparator div textarea {
    width: 95%;
}
.listButton {
    margin: 25px 0;
}
.form h5 {
    margin: 5px 0;
}
.form h4 {
    margin-bottom: 5px;
}