.LineLink {
    display: flex;
    align-items: center;
    text-decoration-line: none;
    color: black;
    border-top: 1px solid rgba(177, 172, 168, 0.4);
    padding-top: 20px;
}
.Title {
    margin-left: 0.5%;
    width: 40%;
}
.Organization {
    margin-left: 0.5%;
    width: 25%;
    text-align: left;
}
.Date {
    margin-left: 8%
}
.Status{
    margin-left: 7%;
}
.Stat {
    margin-left: 4%;
    width: 80px;
}
.detailStats {
    margin-left: 4%;
    width: 12.5%
}
a.LineLink:hover {
    cursor: pointer;
}
a.LineLink:visited {
    color: black;
}
.LineLink .Title{
    text-align: left;
}
.Status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}
.Status div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 25px;
}
.Status p {
    position: absolute;
    left: 15px;
    top: 4px;
}
.Date {
    width: 20%;
    text-align: center;
}
li{
    list-style-type: none;
    padding: 10px 25px;;
}