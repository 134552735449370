button {
    border: 1px solid #F49A56;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.primary {
    background-color: #F49A56;
    color: white;
}
.icon{
    margin-right: 10px;
}
.iconHover{
    margin-right: 10px;
    display: none;
}
button:hover .iconHover{
    display: block;
    transition: 0.2s;
}
button:hover .icon{
    display: none;
}