.headerButton {
    display: flex;
    justify-content: flex-end;
    margin: 25px 0;
}
.section {
    border-radius: 25px;
    box-shadow: 0 4px 10px rgba(0,0,0,0.2);
    padding: 25px;
    position: relative;
}
.section h1 {
    margin: 0;
    background: white;
    padding: 10px;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
}
.section .filterSection {
    margin: 15px 0;
}
.Table header {
    padding: 15px 25px;
    border-radius: 25px;
    background-color: #EFF1F1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
}
.Table ul {
    padding: 0;
}
.Table li {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 25px;
}
.Table li div {
    width: 20%;
    text-align: center;
    font-size: 1.2em;
}
.Collaborator {
    display: flex;
    align-items: center;
}
.profileImg {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: 10px;
}
.donut {
    height: 80px;
    width: 80px !important;
    position: relative;
    margin: auto;
}
.baseDonut {
    position: absolute;
    left: 0;
    top: 0;
    color: rgba(83,67,67,0.2);
}
.donutGraph {
    color: #534343;
}
.donutValue {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #CD1034;
    font-weight: bold;
    font-size: 1em;
}
.donutValue span {
    color: #534343;
}