.modalForm {
    width: 60%;
    margin: 35px auto;
    border-radius: 25px;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 0 4px 10px rgba(0,0,0,0.2);
    padding: 25px;
}
.modalForm header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 25px;
}
.modalForm header h1 {
    margin-left: 30px;
    font-size: 1.3em;
}
.form {
    display: flex;
    flex-direction: column;
}
.form input, .form textarea {
    border-radius: 25px;
    border: 1px solid lightgray;
    padding: 10px;
}
.InputWithAvatar {
    border-radius: 25px;
    border: 1px solid lightgray;
    padding: 10px;
    background-color: white;
    display: flex;
    align-items: center;
}
.InputWithAvatarSelect {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
}

.InputWithAvatar .Avatar, .InputWithAvatarSelect .Avatar {
    margin: 0 10px 0 0;
    border-radius: 50%;
    width: 50px !important;
    height: 50px;
    position: relative;
    overflow: hidden;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}
.InputWithAvatar .Avatar img, .InputWithAvatarSelect .Avatar img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 150%;
    max-height: 150%;
    min-width: 100%;
    min-height: 100%;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}
.InputWithAvatar .Avatar div, .InputWithAvatarSelect .Avatar div {
    height: 100%;
    width: 100%;
}
.form textarea {
    height: 120px;
}
.form label {
    margin: 20px 0;
}
.form div {
    box-shadow: none;
    flex-grow: 0;
    -webkit-flex-grow: 0;
}
.form div:before {
    height: 0;
}
.form fieldset {
    border: none;
}
.form svg {
    color: #EFAC39;
    border-radius: 50%;
    border: 1px solid #EFAC39;
    margin: 0 15px;
}
.form button svg {
    color: #EFAC39;
    border: 0;
    margin: 0;
}
.form .titleAccordion {
    font-weight: normal;
    font-style: italic;
    margin: 0;
}
.form .Input {
    border-radius: 25px;
    border: 1px solid lightgray;
    padding: 10px;
    background-color: white;
}
.form .Input div {
    padding: 0;
    width: 100%;
    justify-content: space-between;
    font-family: "SofiaPro";
}
.DateInput {
    width: auto;
}
.DateInput input {
    text-align: center;
}
.Milestones {
    padding-top: 20px;
}
.Milestones ul {
    padding-left: 0;
    list-style: none;
}
.Milestones ul li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.Milestones ul li [type="text"]{
    width: 90%;
}
.buttonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 25px;
    margin-top: 25px;
}
.form .tooltipHandler input {
    border-radius: 0;
    border: none;
    padding: 0;
}
.titleInput {
    display: flex;
    flex-direction: column;
    position: relative;
}
.compteur{
    position: absolute;
    right: 20px;
    bottom: 8px;
    margin:0;
}
.DateInput input {
    text-align: center;
    color:#F49A56;
    border: 2px solid #F49A56;
    background-color: white;
}
.SMART, .STAR{
    margin: 0 0 5px 0;
    font-size:14px;
    color:#EFAC39;
}
.placeholder {
    font-size: .9em;
    opacity: .8;
}