.card {
    width: calc(100% - 40px);
    padding: 15px 20px;
    border-radius: 10px;
    box-shadow: 0px 6px 14px rgba(177, 172, 168, 0.25);
    background-color: #FFFFFF;
}
.card h3 {
    font-weight: normal;
    font-size: 16px;
    margin: 10px 0;
}
.card .EmptyStage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.card .EmptyStage img {
    height: 200px;
}
.entry {
    list-style: none;
    border-top: 1px solid rgba(177, 172, 168, 0.4);
    width: 100%;
    padding: 5px 0px;
}
.entry a {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    text-decoration: none;
    color: black;
    width: 100%;
}
.entry a div {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: start;
}
.entry .Status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}
.card .entry {
    display: flex;
    flex-direction: row;
}
.card .EntryHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}
.card .EntryHeader div {
    display: flex;
    flex-direction: row;
    justify-content: left;
    color: #B1ACA8;
    font-size: 14px;
    width: 25%;
}
.card .EntryHeader div img {
    height: 20px;
    margin-left: 10px;
}
.card .Sharing {
    height: auto;
}
.card .EntryPagination {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    height: 40px;
    color: grey;
    font-size: 14px;
}
.card .EntryPagination .active {
    color: black;
    cursor: pointer;
}