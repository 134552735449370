.AvatarSelect {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
}

.AvatarSelect .Avatar{
    margin: 0 10px 0 0;
    border-radius: 50%;
    width: 50px !important;
    height: 50px;
    position: relative;
    overflow: hidden;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}
.AvatarSelect .Avatar img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 150%;
    max-height: 150%;
    min-width: 100%;
    min-height: 100%;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}
.AvatarSelect .Avatar div{
    height: 100%;
    width: 100%;
    justify-content: center !important;
}