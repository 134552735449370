.header, .headerManager{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    margin-bottom: 15px;
}
.header h1 img, .headerManager h1 img {
    height: 70px;
    width: 70px;
    position: absolute;
    right: -35px;
    top: -15px;
    z-index: -1;
}
.logo{
    height: 40px;
    margin: 35px;
    margin-left: 5%;
}
.logo a {
    height: 100%;
    display: flex;
    cursor: pointer;
    text-decoration: none;
}
.logo img{
    height: 100%;
}
.header nav {
    display: flex;
    align-items: center;
}
.header nav .navButton {
    padding: 15px;
    margin: 0 30px;
    color: white;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    height: fit-content;
    position: relative;
}
.navMenu {
    display: flex;
    align-items: center;
}
.navMenu .navNotificationMenu{
    cursor: pointer;
}
.navMenu .navNotificationMenu svg {
    color: #F49A56;
}
.navProfileMenu {
    cursor: pointer;
    margin: 0 45px 0 35px;
}
.selectLang {
    width: 100%;
    padding: 5px;
}
.profileImg {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: 0;
    position: relative;
    overflow: hidden;
}
.profileImg img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 150%;
    max-height: 150%;
    min-width: 100%;
    min-height: 100%;
}
.profileImg div {
    width: 100%;
    height: 100%;
}
.profileButton {
    color: black;
    text-decoration: none;
}
.notificationMenu a {
    color: black
}
.activeNotif {
    animation: shake 0.5s;
    animation-iteration-count: 3;
}
.activeNotif:hover {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
}
.notificationMenu .profileButton:hover .close {
    opacity: 1;
    transition: opacity .6s;
}
.close{
    position: absolute;
    right: 5px;
    background-color: rgb(245, 245, 245);
    padding: 1.5px;
    opacity: 0;
    transition: opacity .6s;
    z-index: 5;
}
@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}