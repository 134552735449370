.modalForm {
    width: 60%;
    margin: 35px auto;
    border-radius: 25px;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 0 4px 10px rgba(0,0,0,0.2);
    padding: 25px;
}
.modalForm header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}
.modalForm header .entryTitle {
    display: flex;
}
.modalForm header h1 {
    margin-left: 30px;
    font-size: 1.3em;
}
.form {
    display: flex;
    flex-direction: column;
}
.form input, .form textarea {
    border-radius: 25px;
    border: 1px solid lightgray;
    padding: 10px;
}
.form textarea {
    height: 120px;
}
.form label {
    margin: 20px 0;
}
.form div {
    box-shadow: none;
    flex-grow: 0;
    -webkit-flex-grow: 0;
}
.form div:before {
    height: 0;
}
.form fieldset {
    border: none;
}
.form svg {
    color: #EFAC39;
    border-radius: 50%;
    border: 1px solid #EFAC39;
    margin: 0 15px;
}
.form button svg {
    color: #EFAC39;
    border: 0;
    margin: 0;
}
.form .titleAccordion {
    font-weight: normal;
    font-style: italic;
    margin: 0;
}
.form .Input {
    border-radius: 25px;
    border: 1px solid lightgray;
    padding: 10px;
    background-color: white;
}
.form .Input div {
    padding: 0;
    width: 100%;
    justify-content: space-between;
    font-family: "SofiaPro";
}
.InputWithAvatar {
    border-radius: 25px;
    border: 1px solid lightgray;
    padding: 10px;
    background-color: white;
    display: flex;
    align-items: center;
}
.InputWithAvatarSelect {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
}

.InputWithAvatar .Avatar, .InputWithAvatarSelect .Avatar {
    margin: 0 10px 0 0;
    border-radius: 50%;
    width: 50px !important;
    height: 50px;
    position: relative;
    overflow: hidden;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}
.InputWithAvatar .Avatar img, .InputWithAvatarSelect .Avatar img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 150%;
    max-height: 150%;
    min-width: 100%;
    min-height: 100%;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}
.InputWithAvatar .Avatar div, .InputWithAvatarSelect .Avatar div {
    height: 100%;
    width: 100%;
}
.DateInput input {
    text-align: center;
    color:#F49A56;
    border: 2px solid #F49A56;
    background-color: white;
}
.Milestones {
    padding-top: 20px;
}
.Milestones ul {
    padding-left: 0;
    list-style: none;
}
.Milestones ul li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.Milestones ul li [type="text"]{
    width: 90%;
}
.buttonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 25px;
    margin-top: 25px;
}
.buttonContainer button{
    display: flex;
}
.form .tooltipHandler input {
    border-radius: 0;
    border: none;
    padding: 0;
}
.titleInput {
    display: flex;
    flex-direction: column;
    position: relative;
}
.compteur{
    position: absolute;
    right: 20px;
    bottom: 8px;
    margin:0;
}
.SMART, .STAR{
    margin: 0 0 5px 0;
    font-size:14px;
    color:#EFAC39;
}
.Status{
    height: 33px;
    padding: 10px;
    display: flex;
    align-items: center;
}
.Status div{
    height: inherit;
    margin-right: 4px;
}
.closePage {
    height: auto;
    border: 1px solid #F49A56;
    box-shadow: 0 4px 10px rgb(0 0 0 / 25%);
    cursor: pointer;
    border-radius: 25px;
    padding: 15px 25px;
    background-color: white;
    color: #F49A56;
    font-weight: bold;
    text-decoration: none;
    display: flex;
}
.closePage p {
    margin: 0 0 0 30px;
}
.closeLogo, .addMeasureLogo, .logoBottomButton{
    position: absolute;
    opacity: 100%;
    z-index: 3;
}
.closeHoverLogo, .addMeasureHoverLogo, .logoHoverBottomButton{
    position: absolute;
    opacity: 0%;
    z-index: 4;
}
.closePage:hover {
    
  background: #F49A56;
  color: white;
  transition: background-color .2s;
}

.closePage:hover .closeHoverLogo, .addMeasure button:hover .addMeasureHoverLogo, .buttonContainer button:hover .logoHoverBottomButton{
    opacity: 100%;
    transition: 0.2s;
}
.buttonContainer button p{
    margin: 0 0 0 30px;
}
.addMeasure button{
    display:flex;
}
.addMeasure button p{
    margin: 0 0 0 30px;
}
.addMeasure button img{
    position: absolute;
}
.collaboratorSelect {
    display: flex;
}

.inputManager {
    background-color: rgba(55,55,55, 0.1);
    border-radius: 35px;
    padding: 5px 25px;
    margin-top: 15px;
}
.inputManager p {
    margin: 5px 0;
}
.inputManager textarea {
    width: calc(100% - 20px);
    height: auto;
}
.form h3 {
    text-align: center;
}
.goalLine {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 20px;
}
.goalLine div{
    display: flex;
    flex-direction: column;
}
.goalLine div label {
    margin-bottom: 10px;
}
.goalLine_firstItem {
    width: 100%;
}
.goalLine_firstItem input {
    width: 95%;
}