.tooltipsIcon{
    z-index: 1;
    width: 16px;
    height: 16px;
    opacity: 0.5;
    position: relative;
    margin-left: 15px;
}
.closetooltips {
    align-items: right;
    width: 12.5px;
    height: 14.7px;
    opacity: 0.5;
    right: 20px;
    top: 20px;
    position: absolute;
    z-index: 5;
}
div h3 .tooltips, p .tooltips, .tooltips {
    height: auto;
    z-index: 2;
    background-color: #FFFFFF !important;
    border-radius: 10px;
    border: 2px #F49A56 solid;
    position: absolute;
    padding: 10px;
    display: flex;
    justify-content: center;
    box-shadow: 0 4px 10px rgb(0 0 0 / 50%);
}
.openToolTips{
    animation: openToolTips 0.3s;
}
div h3 .tooltips .fondToolTips:first-of-type, p .tooltips .fondToolTips:first-of-type, .tooltips .fondToolTips:first-of-type  {
    width: auto;
    height: 80%;
    opacity: 0.33;
    position: absolute;
    z-index: 3;
    top: 10%;
}
div h3 .tooltips .tooltipsContent, p .tooltips .tooltipsContent, .tooltips .tooltipsContent {
    display: flex;
    margin: 15px 10px;
    z-index: 4;
    font-size: 16px;
    font-weight: normal;
}
div h3 .tooltips .tooltipsContent:first-of-type .tooltipsText:first-of-type, p .tooltips .tooltipsContent:first-of-type .tooltipsText:first-of-type, .tooltips .tooltipsContent:first-of-type .tooltipsText:first-of-type{
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 25px;
    font-size: 16px;
    text-align: left;
}
.tooltipsIcon:hover,.closetooltips:hover{
    transition: .2s;
    opacity: 0.7;
}
.checkbox{
    visibility: hidden;
}