.LineLink {
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration-line: none;
    color: black;
    border-top: 1px solid rgba(177, 172, 168, 0.4);
    padding-top: 20px;
}
.Collaborator{
    margin-left: 10px;
}
.Title {
    width: 40%;
    margin-left: 2%;
}
.Date {
    margin-left: 12%
}
.Status{
    margin-left: 12%;
    max-width: 10%
}
.Stats {
    margin-left: 5.3%;
}
.LineLink:hover {
    cursor: pointer;
}
.LineLink:visited {
    color: black;
}
.LineLink .Title{
    text-align: left;
}
.Status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}
.Status div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 25px;
}
.Status p {
    position: absolute;
    left: 15px;
    top: 4px;
}
.Date {
    width: 20%;
    text-align: center;
}
.Actions {
    width: 10%;
    display: flex;
    justify-content: space-evenly;
}

.Actions .link {
    background-color: #E0E3E3;
    color: black;
    border-radius: 50%;
    cursor: pointer;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Actions .link svg {
    font-size: 1.3em;
}
.Actions .link:hover {
    background-color: #F49A56;
    color: white;
}
li{
    list-style-type: none;
    padding: 10px 25px;;
}
.Collaborator {
    margin: 0 10px 0 0;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: relative;
    overflow: hidden;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}
.Collaborator img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 150%;
    max-height: 150%;
    min-width: 100%;
    min-height: 100%;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}
.Collaborator div {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    padding: 0;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 150%;
    max-height: 150%;
    min-width: 100%;
    min-height: 100%;
}
