.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmarkNotComplet {
    position: absolute;
    top: -12px;
    left: 0;
    height: 25px;
    width: 25px;
    z-index: 2;
}

.checkmarkComplet {
    position: absolute;
    top: -12px;
    left: 0;
    height: 25px;
    width: 25px;
    z-index: 10;
    opacity: 0%;
}

.container:hover input ~ .checkmarkNotComplet {
    transition: .2s;
    opacity: 0.7;
}

.container input:checked ~ .checkmarkComplet {
    transition: .2s;
    opacity: 1;
}

.container input:checked ~ .checkmarkNotComplet {
    transition: .2s;
    opacity: 0;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.close {
    margin-left : 10px;
    cursor: pointer;
}
.close:hover {
    transition: .2s;
    opacity: 0.7;
}