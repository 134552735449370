.logo {
    margin-bottom: 35px;
}
.logo a {
    height: 100%;
    display: flex;
    cursor: pointer;
    text-decoration: none;
}
.logo img{
    width: 100%;
}
.sidebar {
    width: calc(16% - 40px);
    height: calc(100% - 40px);
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0 7px 70px 0;
    background-color: #F49A56;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    overflow: auto;
}
.sidebar .menuItem {
    display: flex;
    color: white;
    text-decoration: none;
    padding: 5px 10px;
    height: 40px;
    align-items: center;
    border-radius: 50px;
}
.sidebar .menuItem figure {
    margin: 0 10px 0 0;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: relative;
    overflow: hidden;
}
.sidebar .menuItem figure img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 150%;
    max-height: 150%;
    min-width: 100%;
    min-height: 100%;
}
.sidebar .menuItem figure div {
    width: 100%;
    height: 100%;
}
.sidebar .menuItem span {
    font-weight: bold;
    font-size: 14px;
}
.sidebar .menuItem img {
    margin-right: 10px;
    width: 25px;
    height: 25px;
}
.sidebar .collapseItem div {
    background-color: transparent !important;
    box-shadow: none;
    padding: 0;
    margin: 0;
}
.sidebar .talentList, .sidebar .talentList .menuItem  {
    margin: 0;
    padding-left: 15px;
}
.sidebar .talentList {
    margin-bottom: 25px;
}

@media screen and (max-width: 1250px) {
    .logo {
        margin: 10px 10px 25px 10px;
    }
    .sidebar .menuItem {
        padding: 5px 10px;
    }
}
@media screen and (max-width: 1050px) {
    .sidebar .menuItem {
        padding: 0 5px;
    }
    .sidebar {
        padding: 20px 10px;
    }
    .sidebar .menuItem img {
        margin-right: 5px;
    }
    .talentList {
        padding-left: 10px;
    }
}