@font-face {font-family: "SofiaPro";
  src: url("./assets/fonts/bb696e579172b607a802a74b19f68c3b.eot"); /* IE9*/
  src: url("./assets/fonts/bb696e579172b607a802a74b19f68c3b.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./assets/fonts/bb696e579172b607a802a74b19f68c3b.woff2") format("woff2"), /* chrome、firefox */
  url("./assets/fonts/bb696e579172b607a802a74b19f68c3b.woff") format("woff"), /* chrome、firefox */
  url("./assets/fonts/bb696e579172b607a802a74b19f68c3b.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("./assets/fonts/bb696e579172b607a802a74b19f68c3b.svg#SofiaPro-ExtraLight") format("svg"); /* iOS 4.1- */
  font-weight: normal;
}
@font-face {font-family: "SofiaPro";
  src: url("./assets/fonts/2bddd8cacbb264e5533b00d2513b6ebc.eot"); /* IE9*/
  src: url("./assets/fonts/2bddd8cacbb264e5533b00d2513b6ebc.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./assets/fonts/2bddd8cacbb264e5533b00d2513b6ebc.woff2") format("woff2"), /* chrome、firefox */
  url("./assets/fonts/2bddd8cacbb264e5533b00d2513b6ebc.woff") format("woff"), /* chrome、firefox */
  url("./assets/fonts/2bddd8cacbb264e5533b00d2513b6ebc.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("./assets/fonts/2bddd8cacbb264e5533b00d2513b6ebc.svg#Sofia Pro Bold") format("svg"); /* iOS 4.1- */
  font-weight: bold;
}
* {
  font-family: "SofiaPro" , -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
}
a {
  text-decoration: none;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F3F5F7;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
textarea {
  resize: vertical;
}
.primary-button, .secondary-button, .third-button, input[type=file]::file-selector-button {
  border: 1px solid #F49A56;
  box-shadow: 0 4px 10px rgba(0,0,0,0.25);
  cursor: pointer;
  border-radius: 25px;
  padding: 15px 25px;
  background-color: white;
  color: #F49A56;
  font-weight: bold;
  text-decoration: none;
}
.primary-button {
  background-color: #F49A56;
  color: white;
}
.primary-button:hover {
  background: white;
  color: #F49A56;
  transition: background-color .2s;
}
.secondary-button:hover {
  background: #F49A56;
  color: white;
  transition: background-color .2s;
}
.third-button, input[type=file]::file-selector-button {
  border: 1px solid #CD1034;
  color: #CD1034;
}
.third-button:hover, input[type=file]::file-selector-button:hover {
  background: #CD1034;
  color: white;
  transition: background-color .2s;
}
.Draft{
  border-left: 6px solid lightgrey;
}
.Shared {
  border-left: 6px solid #EFAC39;
}
.Active, .Sent, .SharedByCollaborator, .SharedByManager, .SharedByBoth{
  border-left: 6px solid #05A0B2;
}
.Achieved, .Received, .Reviewed, .Accepted, .ValidatedByManager, .ValidatedByBoth {
  border-left: 6px solid #10B43E;
}
.Rejected {
  border-left: 6px solid #CD1034;
}
.Mui-selected {
  background-color: #F49A56 !important;
}
input, textarea, select, option {
  font-family: "SofiaPro" !important;
  font-size: .9em !important;
}
.error {
  color: red;
}
.success {
  color: #10B43E;
}
.active {
  background-color: #F3F5F7;
  box-shadow: inset 5px 5px 5px rgb(0 0 0 / 10%);
  color: #5E5955;
}
.active span {
  color: #5E5955;
}
.active .activeIcon {
  display: block;
}
.activeIcon {
  display: none;
}
.active .icon {
  display: none;
}
span.Draft{
  background-color: lightgrey;
  width: 1px;
  height: 33px;
  border-radius: 4px;
  margin-right: 10px;
}
span.Shared {
  background-color: #EFAC39;
  width: 1px;
  height: 33px;
  border-radius: 4px;
  margin-right: 10px;
}
span.Active, span.Sent, span.SharedByCollaborator, span.SharedByManager, span.SharedByBoth{
  background-color: #05A0B2;
  width: 1px;
  height: 33px;
  border-radius: 4px;
  margin-right: 10px;
}
span.Achieved, span.Received, span.Reviewed, span.Accepted, span.Consolidated, span.ValidatedByManager, span.ValidatedByBoth {
  background-color: #10B43E;
  width: 1px;
  height: 33px;
  border-radius: 4px;
  margin-right: 10px;
}
.avatar {
  border-radius: 50%;
  width: 125px;
  height: 125px;
  overflow: hidden;
  margin-top: 25px;
  position: relative;
}
.avatar img {
  transform: translate(-50%, -50%);
  max-height: 150%;
  max-width: 150%;
  position: absolute;
  left: 50%;
  top: 50%;
}
.supportButton {
  position: fixed;
  bottom: 5px;
  right: 5px;
  border: 1px solid #F49A56;
  box-shadow: 0 4px 10px rgba(0,0,0,0.25);
  cursor: pointer;
  border-radius: 25px;
  background-color: white;
  font-weight: bold;
  text-decoration: none;
}
.supportIVCButton {
  position: fixed;
  bottom: 0px;
  right: 180px;
  cursor: pointer;
}
.supportIVCButton svg {
  color: #F49A56;
  font-size: 38px;
}
.supportButton a {
  text-decoration: none;
  color: #F49A56;
  padding: 10px 15px;
  display: block;
}
.optional {
  font-size: 0.8em;
  color: grey;
}
.popUp .MuiPaper-root {
  border: 3px solid #F49A56 !important;
  padding: 25px;
  text-align: center;
}

.MuiFormControlLabel-root {
  margin: 0 !important;
  flex-direction: row !important;
}
.MuiFormControlLabel-root svg{
  border-radius: initial !important;
  border: none !important;
  margin: auto !important;
}
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #EFAC39 !important;
}
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background-color: #EFAC39 !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 0 !important;
}