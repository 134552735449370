.closeModal {
    position: absolute;
    right: 25px;
    cursor: pointer;
}
.popUpButtons {
    display: flex;
    justify-content: center;
}
#alert-dialog-title{
    display: flex;
}
.figure {
    margin-left: 30%;
    width: 40%;
    text-align: center;
}
.figure img {
    width: 100%;
}