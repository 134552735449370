.progress {
    display: flex;
    align-items: center;
    margin: 15px 0;
}
.progress span {
    margin-right: 15px;
}
.progress progress {
    width: 93%;
}
.progress progress[value]{
    -webkit-appearance: none;
    appearance: none;
    height: 1.3em;
}
.progress progress[value]::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 25px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}
.progress progress[value]::-webkit-progress-value {
    border-radius: 25px;
    background-color: #10B43E;
}