.Header {
    padding: 15px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
}
.Header div {
    display: flex;
    align-items: center;
}
.headerDetailStat{
    margin: 0 1%;
    width: 12.5%;
    justify-content:center;
}
.headerStat {
    margin-left: 4%;
}
.headerDetailTitle {
    margin-left: 0.5%;
    width: 25%;
}
.headerTitle {
    margin-left: 0.5%;
    width: 36%;
}
.headerDate {
    margin-left: 8%;
    width: 20%;
    justify-content: center;
}
.headerStatus {
    margin-left: 7%
}
.List {
    width: calc(100% - 40px);
    padding: 15px 20px;
    background-color: #FFFFFF;
    height: inherit;
    border-radius: 25px;
}
.List h3 {
    font-weight: normal;
    font-size: 16px;
    margin: 10px 0;
}
.List .EmptyStage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.List .EmptyStage img {
    height: 200px;
}
.entry {
    list-style: none;
    border-top: 1px solid rgba(177, 172, 168, 0.4);
    width: 100%;
    padding: 5px 0px;
}
.entry a {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    text-decoration: none;
    color: black;
    width: 100%;
}
.entry a div {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: start;
}
.entry .Status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}
.List .entry {
    display: flex;
    flex-direction: row;
}
.List .EntryHeader div {
    display: flex;
    flex-direction: row;
    justify-content: left;
    color: #B1ACA8;
    font-size: 14px;
    width: 25%;
}
.List .EntryHeader div img {
    height: 20px;
    margin-left: 10px;
}
.List .Sharing {
    height: auto;
}
.List .EntryPagination {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    height: 40px;
    color: grey;
    font-size: 14px;
}
.List .EntryPagination .active {
    color: black;
    cursor: pointer;
}
.pageTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Status{
    height: 33px;
    padding: 10px;
    display: flex;
    align-items: center;
}
.Status div{
    height: inherit;
    margin-right: 4px;
}