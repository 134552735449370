.headerButton {
    display: flex;
    justify-content: flex-end;
    margin: 25px 2% 25px 0;
}
.section {
    position: relative;
    margin-right: 2%;
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 25px;
}
.sectionListe {
    width: calc(60% - 40px);
    border: 1px solid #F4DDDC;
    border-radius: 25px;
    margin-right: 10px;
    box-shadow: 0px 6px 14px rgba(177, 172, 168, 0.25);
    background-color: #FFFFFF;
}
.sectionRight {
    background-color: #FFFFFF;
    width: calc(40% - 40px);
    border: 1px solid #F4DDDC;
    border-radius: 25px;
    box-shadow: 0px 6px 14px rgba(177, 172, 168, 0.25);
    padding: 25px;
    height: 500px;
    position: sticky;
}
.section h1 {
    width: 500px;
    margin: 10px;
    padding: 10px;
    text-align: left;
    display: flex;
    align-items: center;
}
@media screen and (max-width: 1400px) {
    .section {
        margin-right: 1%;
    }
}